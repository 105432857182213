import { useState, useMemo, useCallback } from 'react';
import { useQueryCenters } from '../lib/providers-utils/use-query-centers';
import { useQueryPageProviders } from '../lib/providers-utils/use-query-page-providers';
import { useQueryPapiProviders } from '../lib/providers-utils/use-query-papi-providers';
import {
  getCentersAsOptions,
  getTypeFilterOptions
} from '../lib/providers-utils';
import { PapiProvider, SanityPerson } from '../../graphql-types';

type Filter = 'PROVIDER_TYPE' | 'LOCATION';
export type TFilterFunction = ({}: { filter: Filter; value: string }) => void;
const initialState: Record<Filter, string> = {
  PROVIDER_TYPE: '',
  LOCATION: ''
};

/**
 * We need to match/filter the providers in the sanity page and the papi data
 * @param {PapiProvider} providersFromPapi list directly from papi
 * @param {SanityPerson} providersfromSanityPage component from providers page
 * @returns PapiProvider List
 */
const getProcessedProviders = (
  providersFromPapi: PapiProvider[],
  providersfromSanityPage: SanityPerson[]
) => {
  const secondaryProviders: PapiProvider[] = providersFromPapi.filter(
    pPapi =>
      !providersfromSanityPage.some(pSanity => pPapi.sanityID === pSanity._id)
  );
  const primaryProviders: PapiProvider[] = providersfromSanityPage.flatMap(
    provider => {
      const findedProviderFromPapi = providersFromPapi?.find(
        p => p.sanityID === provider._id
      );
      if (findedProviderFromPapi) {
        return findedProviderFromPapi;
      }
      return [];
    }
  );
  return { providers: [...primaryProviders, ...secondaryProviders] };
};

export const useProviders = () => {
  const [filters, setFilters] = useState(initialState);
  const { nodes: centersQuery } = useQueryCenters();
  /**
   * The marketing team wants to sort/filter the providers shown on the page,
   * but we're using data from Papi, so we need to search for both (sanity-page/Papi) and filter them.
   * At the moment we need to get the data from the providers page with a static query,
   * once we have the page connected with the page builder we can remove the static query and pass the providers as a prop.
   */
  const { papiProviders } = useQueryPapiProviders();
  const { pageProviders } = useQueryPageProviders();
  const { providers } = getProcessedProviders(papiProviders, pageProviders);
  const centers = getCentersAsOptions(centersQuery);
  const providerTypes = getTypeFilterOptions();

  const filteredData = useMemo(() => {
    return providers.filter(p => {
      if (
        filters.LOCATION &&
        !p.centers.find(c => c.center.id === filters.LOCATION)
      ) {
        return false;
      }
      if (filters.PROVIDER_TYPE) {
        if (filters.PROVIDER_TYPE === 'HEALTH_COACH') {
          return p.type === filters.PROVIDER_TYPE;
        } else {
          return p.type !== 'HEALTH_COACH';
        }
      }
      return true;
    });
  }, [filters]);

  const setFilter: TFilterFunction = useCallback(({ filter, value }) => {
    setFilters(prev => ({ ...prev, [filter]: value }));
  }, []);

  return {
    providers: filteredData,
    centersOptions: centers,
    providerOptions: providerTypes,
    filters,
    setFilter
  };
};
