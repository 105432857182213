import { useStaticQuery, graphql } from 'gatsby';
import { PapiCenter } from '../../../graphql-types';

interface QueryData {
  allPapiCenter: { nodes: PapiCenter[] };
}

export const useQueryCenters = () => {
  const staticData: QueryData = useStaticQuery(graphql`
    query CentersDataQuery {
      allPapiCenter {
        nodes {
          id
          name
          isVirtual
          city
          state
        }
      }
    }
  `);
  return staticData.allPapiCenter;
};
