import { useStaticQuery, graphql } from 'gatsby';
import { PapiProvider } from '../../../graphql-types';

interface QueryData {
  allPapiProvider: { nodes: PapiProvider[] };
}

export const useQueryPapiProviders = () => {
  const staticData: QueryData = useStaticQuery(graphql`
    query PapiProvidersDataQuery {
      allPapiProvider(
        filter: {
          sanity: {
            slug: { current: { ne: null } }
            hideinWebsite: { ne: true }
            role: {
              in: [
                "Doctor"
                "Health Coach"
                "Nurse Practitioner"
                "Physician Assistant"
              ]
            }
          }
        }
      ) {
        nodes {
          ...PapiProviderProfile
        }
      }
    }
  `);

  return { papiProviders: staticData?.allPapiProvider?.nodes ?? [] };
};
