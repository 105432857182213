import { useStaticQuery, graphql } from 'gatsby';
import { SanityDoctorsList } from '../../../graphql-types';

interface QueryData {
  sanityCorePage: {
    pageBuilderComponents: {
      components: {
        __typename: string;
      }[];
    };
  };
}

export const useQueryPageProviders = () => {
  const staticData: QueryData = useStaticQuery(graphql`
    query PageProvidersDataQuery {
      sanityCorePage(slug: { current: { eq: "providers" } }) {
        pageBuilderComponents {
          components {
            ... on SanityDoctorsList {
              providers {
                _id
              }
            }
          }
        }
      }
    }
  `);
  /**
   * We are fetching a specific component in the providers page ProvidersList
   * This component has the right providers list to display in the screen
   */
  const pageProviders = staticData?.sanityCorePage?.pageBuilderComponents?.components.find(
    c => c.__typename === 'SanityDoctorsList'
  ) as SanityDoctorsList;

  return { pageProviders: pageProviders?.providers ?? [] };
};
