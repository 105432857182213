/* tslint:disable */
/* eslint-disable */
//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PapiProviderType {
  CARE_MANAGER = 'CARE_MANAGER',
  CARE_MANAGER_PROVIDER_GROUP = 'CARE_MANAGER_PROVIDER_GROUP',
  DOCTOR = 'DOCTOR',
  HEALTH_COACH = 'HEALTH_COACH',
  MXA = 'MXA',
  MX_PROVIDER_GROUP = 'MX_PROVIDER_GROUP',
  NURSE = 'NURSE',
  PEDIATRICIAN = 'PEDIATRICIAN',
  PHLEBOTOMIST = 'PHLEBOTOMIST',
}

//==============================================================
// END Enums and Input Objects
//==============================================================
