import { PapiCenter } from 'graphql-types';
import { PapiProviderType } from '../../types/papi-global-types';
import { states, disabledStates } from '../../data/states';
import { centers as cities } from '../../data/centers';
import { capitalizeFirstLetter } from '../helpers';

export const providerTypes = [
  PapiProviderType.DOCTOR,
  PapiProviderType.HEALTH_COACH
];

export const getCentersAsOptions = (centers: PapiCenter[]) =>
  centers
    .sort((current, next) => {
      const nextCenterLocation = next.isVirtual ? next.state : next.city;
      const currentCenterLocation = current.isVirtual
        ? current.state
        : current.city;
      return currentCenterLocation.localeCompare(nextCenterLocation);
    })
    .flatMap((center: PapiCenter) => {
      const { isVirtual, id, state, city } = center;
      const location = isVirtual
        ? states.find((s: any) => s.value === state)
        : cities.find((c: any) => !c.isClosed && c.value === city);
      if (!location) {
        return [];
      }
      return {
        label: location?.label,
        value: disabledStates.includes(state) ? null : id,
        online: isVirtual
      };
    });

export const formatTypeName = (job: string) => {
  switch (job) {
    case 'DOCTOR':
      return 'Medical Providers';
    case 'HEALTH_COACH':
      return 'Health Coaches';
    default:
      return capitalizeFirstLetter(job);
  }
};

export const getTypeFilterOptions = () =>
  providerTypes.map((type: string) => {
    return {
      value: type,
      label: `${formatTypeName(type)}`
    };
  });
